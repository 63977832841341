
import Filters from "@/components/orders/Filters.vue";
import router from "@/bootstrap/router";
import StartPickModal from "@/components/order/StartPickModal.vue";
import isDateTomorrow from "@/utilities/isDateTomorrow";
import { Order } from "@/types/Order";
import { StartPickFromBarcode } from "@/types/StartPickFromBarcode";
import { defineComponent } from "vue";
import {
  AbandonmentReasonsEnum,
  AbandonmentReasonsI18nMap,
} from "@/utilities/AbandonmentReasons";
import { TabGroupEnum } from "@/store/orders";

export default defineComponent({
  name: "Orders",
  components: {
    StartPickModal,
    Filters,
  },
  data: () => ({
    filtersVisible: false,
    loadingOrders: true,
    loadingOverview: true,
    loadingOrderId: null,
    loadingBarcodeScan: false,
    displayStartPickModal: false,
    packageIdentifier: null,
  }),
  computed: {
    pickButtonColor() {
      return (order: Order) => {
        if (order.userId === this.authUserId) {
          return "success";
        }
        return null;
      };
    },
    pickButtonIcon() {
      return (order: Order) => {
        if (
          order.taken ||
          this.orderCustomerHasStartPickFromBarcodeEnabled(order.customer)
        ) {
          return "mdi-alert-rhombus-outline";
        }
        if (order.splitPack) {
          return "mdi-package-variant";
        }
        return null;
      };
    },
    pickButtonText() {
      return (order: Order) => {
        if (order.splitPack) {
          return this.$t("wms");
        }
        if (order.userId === this.authUserId) {
          return this.$t("continue");
        }
        return this.$t("pick");
      };
    },
    authUserId() {
      return this.$store.getters["core/getAuthUserId"];
    },
    cardColor() {
      return (order: Order) => {
        if (order.taken || order.splitPack) {
          return "purple-light";
        }
        if (this.orderCustomerHasStartPickFromBarcodeEnabled(order.customer)) {
          return "grey";
        }
        return null;
      };
    },
    abandonmentReasonLabel() {
      return (abandonmentReason: AbandonmentReasonsEnum) => {
        const i18n = AbandonmentReasonsI18nMap.find(
          (item) => item.id === abandonmentReason
        );
        return i18n?.label ?? abandonmentReason;
      };
    },
    appliedFilters() {
      const appliedFilters = [
        this.$store.state.orders.customerFilter,
        this.$store.state.orders.orderTypeFilter,
        this.$store.state.orders.courierFilter,
        this.$store.state.orders.skuFilterType,
        this.$store.state.orders.primeFilter,
        this.activeTabGroup === TabGroupEnum.pending
          ? this.$store.state.orders.pendingStatusFilter
          : null,
        this.$store.getters["orders/getSortName"],
      ];
      return appliedFilters.filter((item) => !!item);
    },
    activeTabGroup() {
      return this.$store.state.orders.activeTabGroup;
    },
    orders(): Order[] {
      return this.$store.state.orders.orders;
    },
    readyTabGroupTotal() {
      return this.$store.state.orders.readyTabTotal;
    },
    pendingTabGroupTotal() {
      return this.$store.state.orders.pendingTabTotal;
    },
    page(): number {
      return this.$store.state.orders.page;
    },
    totalPages() {
      return Math.ceil(
        this.$store.state.orders.activeTabGroupTotal /
          this.$store.state.orders.perPage
      );
    },
    startPickFromBarcode(): StartPickFromBarcode[] | [] {
      return this.$store.state.orders.startPickFromBarcode;
    },
    startPickFromBarcodeEnabled(): boolean {
      for (const credential of this.startPickFromBarcode) {
        if (credential?.start_pick_from_barcode === 1) {
          return true;
        }
      }
      return false;
    },
    orderCustomerHasStartPickFromBarcodeEnabled(): (
      customer: number
    ) => boolean {
      return (customerHubooBox: number) => {
        for (const credential of this.startPickFromBarcode) {
          if (
            credential?.start_pick_from_barcode === 1 &&
            credential?.huboobox === customerHubooBox
          ) {
            return true;
          }
        }
        return false;
      };
    },
    isTomorrowsPick() {
      return (sbt: string | null) => isDateTomorrow(sbt);
    },
  },
  created() {
    this.refreshOrdersAndOrdersOverview();
  },
  methods: {
    handleActiveTabGroup(tabGroup: TabGroupEnum) {
      if (this.loadingOrders || this.loadingOverview) {
        return;
      }
      if (tabGroup === TabGroupEnum.ready) {
        this.$store.commit("orders/setPendingStatusFilter", null);
      }
      this.$store.commit("orders/setPage", 1);
      this.$store.commit("orders/setTabGroup", tabGroup);
      this.refreshOrdersAndOrdersOverview();
    },
    refreshOrdersAndOrdersOverview() {
      Promise.all([this.getOrders(), this.getOrdersOverview()])
        .then(() => {
          // To correctly calculate tab totals we need to make sure both data endpoints are loaded and processed
          this.$store.commit("orders/recalculateTabTotal");
        })
        .finally(() => {
          this.loadingOrders = false;
          this.loadingOverview = false;
        });
    },
    startOrder(orderId: number, customer: number): void {
      if (this.orderCustomerHasStartPickFromBarcodeEnabled(customer)) {
        this.displayStartPickModal = true;
        return;
      }
      router.push({ name: "order", params: { orderId: orderId.toString() } });
    },
    goToStartPickFromBarcode(): void {
      router.push({
        name: "start-pick-with-barcode",
        params: { hub_id: this.$store.state.core.hubId },
      });
    },
    handleCloseStartPickModal(): void {
      this.displayStartPickModal = false;
    },
    handlePage(page: number): void {
      this.$store.commit("orders/setPage", page);
      this.getOrders();
    },
    async getOrdersOverview(): Promise<void> {
      this.loadingOverview = true;
      await this.$store.dispatch("orders/getOrdersOverview");
      this.loadingOverview = false;
    },
    async getOrders(): Promise<void> {
      this.loadingOrders = true;
      await this.$store.dispatch("orders/getOrders");
      this.loadingOrders = false;
    },
  },
});
