import { render, staticRenderFns } from "./WavePickStart.vue?vue&type=template&id=7dce080a&scoped=true&"
import script from "./WavePickStart.vue?vue&type=script&setup=true&lang=ts&"
export * from "./WavePickStart.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./WavePickStart.vue?vue&type=style&index=0&id=7dce080a&prod&scoped=true&lang=css&"
import style1 from "./WavePickStart.vue?vue&type=style&index=1&id=7dce080a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dce080a",
  null
  
)

export default component.exports